






























import { Component, Mixins, Vue } from 'vue-property-decorator'
import AuthModule from '@/store/modules/authModule'
import { Action, Getter } from '@/store/decorators'
import AppLogo from '@core/layouts/components/AppLogo.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { User } from '@/models/User'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip
} from 'bootstrap-vue'

@Component({
  name: 'SocialLogin',
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    AppLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  directives: {
    'b-tooltip': VBTooltip
  }
})

export default class SocialLogin extends Vue {
  @Action(AuthModule, 'loginWithProvider') private loginWithProvider!: (providerName: string) => Promise<User>
  @Getter(AuthModule, 'activeUser') private activeUser!: User

  private async providerLogin (providerName) {
    // console.log(providerName)
    try {
      await this.loginWithProvider(providerName)
      this.$router.replace(getHomeRouteForLoggedInUser('customer'))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            // position: 'top-right',
            props: {
              title: 'Welcome',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as customer Now you can start to explore!`
            }
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
          // console.log(error)
          // this.$refs.loginForm.setErrors(error.response.data.error)
        })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: e.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
      // console.log(e)
    }
  }
}
